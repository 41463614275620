import type { HighlightButtonProps } from '../Types'
import LockIcon from './LockIcon'
import SquareButton from '../SquareButton'
import { useMemo } from 'react'

function HighlightButton({
  encrypted,
  unlocked,
  href,
  ...restProps
}: Readonly<HighlightButtonProps>) {

  const processedHref = useMemo(() => {
    if (encrypted) {
      return unlocked ? href : undefined
    }
    return href
  }, [encrypted, href, unlocked])

  return (
    <SquareButton
      {...restProps}
      iconBlurred={encrypted && !unlocked}
      href={processedHref}
      labelClass='line-clamp-1'
    >
      {encrypted && (
        <LockIcon unlocked={unlocked} />
      )}
    </SquareButton>
  )
}

export default HighlightButton
